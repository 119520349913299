<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Add New Waybills</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 comp-grid" >
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid card">
                <div class="grid flex">

                    <!--start card add-->
                    <div class="col-12 md:col-12"> 
<Avatar :label=" total.length" class="card bg-primary shadow-6  text-900 font-bold mr-3"></Avatar>
                       
                                <Avatar class="card shadow-6 bg-primary text-900 font-bold" icon="pi pi-print" @click="visibleFull = true"></Avatar></div>
                    <div class="col-6 md:col-6">
<div clas="card">

 <div class="grid pt-4">
                                            <div class="col-4">
                                                User
                                                <div id="ctrl-control_number-holder"> 
                                                    <InputText ref="ctrluser" v-model.trim="formData2.user"  label="User" type="text" placeholder="Enter User"      
                                                    class=" w-full" >
                                                    </InputText>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                Ref
                                                <div id="ctrl-tracking-holder"> 
                                                     <InputText ref="ctrlref" v-model.trim="formData2.ref"  label="Ref" type="text" placeholder="Enter Ref"      
                                                    class=" w-full" >
                                                    </InputText>
                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-4">
                                                Date
                                                <div id="ctrl-city-holder"> 
                                                    <div class="p-inputgroup">
                                                         <InputText ref="ctrldate" v-model.trim="formData2.logdate"  label="Date" type="text" placeholder="Enter Date"    readonly  
                                                    class=" w-full" >
                                                    </InputText>
                                                       
                                                        <span class="p-inputgroup-addon bg-primary" @click="addCounter(formData2.ref,formData2.logdate); openWayBill();" >submit</span>
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        </div>

</div>
</div>
<!--end card add-->
<div class="md:col-6 col-6 sm:col-12 comp-grid pt-5 " v-if="showWayBill === false ">
                        <div  class="bg-indigo-50 card">
<div clas="text-3xl font-bold text-900">No 
Ticket Yet </div>
                            </div></div>

                    <div class="md:col-6 col-6 sm:col-12 comp-grid" v-if="showWayBill === true ">
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                               
                                <form ref="observer" tag="form" @submit.prevent="submitForm()">
                                    <div v-for="(formData, row) in arrFormData" :key="row">
                                        <div class="grid">
                                            <div class="col-4">
                                                Control Number 
                                                <div id="ctrl-control_number-holder"> 
                                                    <InputText ref="ctrlcontrol_number" v-model.trim="formData.control_number"  label="Control Number" type="text" placeholder="Enter Control Number"      
                                                    class=" w-full" :class="getErrorClass('control_number')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('control_number')" class="p-error">{{ getFieldError('control_number') }}</small> 
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                Tracking 
                                                <div id="ctrl-tracking-holder"> 
                                                    <InputText ref="ctrltracking" v-model.trim="formData.tracking"  label="Tracking" type="text" placeholder="Enter Tracking"      
                                                    class=" w-full" :class="getErrorClass('tracking')" @input="dBounce" v-focus>
                                                    </InputText>
                                                    <small v-if="isFieldValid('tracking')" class="p-error">{{ getFieldError('tracking') }}</small> 
                                                </div>
                                            </div>
                                            
                                           
                                            <div class="col-4">
                                                Date
                                                <div id="ctrl-city-holder"> 
                                                    <div class="p-inputgroup">
                                                         <InputText ref="ctrldate" v-model.trim="formData.date"  label="Date" type="text" placeholder="Enter Date"    readonly  
                                                    class=" w-full" :class="getErrorClass('date')">
                                                    </InputText>
                                                        <span class="p-inputgroup-addon bg-red-500" @click="removeFormRow(row)">x</span>
                                                       
                                                    </div>
                                                    <small v-if="isFieldValid('date')" class="p-error">{{ getFieldError('date') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div class="">
                            <!--start print modal-->
                            <Sidebar v-model:visible="visibleFull" :base-z-index="10000" position="full">
                                <div class="grid">  
                                    <div class="col-12 md:col-12 print">
                                        <div class=" card bg-indigo-50 p-3  border-dotted" v-for="(item,index) in total" :key="item.id" style="page-break-after:always;  ">
                                            <div>
                                                <api-data-source   :api-path="`waybills/getprev/${item.control_number}`" :first-record="true">
                                                    <template v-slot="req">
                                                        <div>
                                                            <div class="grid">
                                                                <div class="md:w-3; lg:w-3 w-3" >
                                                                    <div class="p-3" style="border:solid; width:60px;">
                                                                        <!-- 
                                                                        Template to display each record 
                                                                        use {{req.response.fieldname}} to display each field value
                                                                        -->
                                                                        <template v-if="!req.loading">
                                                                            <div v-if="req.response">{{ req.response.num }}</div>
                                                                            <div v-else>No record found</div>
                                                                        </template>
                                                                        <!-- Loading -->
                                                                        <template v-else>
                                                                            <div class="text-center">
                                                                                <ProgressSpinner />
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div></div>
                                                        </template>
                                                    </api-data-source>
                                                    <span class="text-900 font-bold">{{index +1}} of {{total.length}}</span>
                                                    <Divider align="left" type="dashed">
                                                        <span class="text-900 text-2xl font-bold ex" style="">Controll Number</span>
                                                        </Divider>
                                                        <span class="text-2xl font-bold text-900">{{item.control_number}}</span>
                                                        <div>
                                                            <Divider align="left" type="dashed">
                                                                <b>Total</b>
                                                                </Divider> <span class="text-2xl font-bold text-900">{{total.length}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></div>
                                                <Button icon="pi pi-print" @click="doPrint"></Button>
                                            </Sidebar>
                                            <!--end print modal-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted, ref,watch } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required } from '@/services/validators';
    import { ApiService } from '@/services/api';
    import { utils } from '@/utils';
    import { useApp } from '@/composables/app.js';
    import { useAddPage } from '@/composables/addpage.js';
    import { useAuth } from '@/composables/auth';
    const props = defineProps({
        pageName : {
            type : String,
            default : 'waybills',
        },
        routeName : {
            type : String,
            default : 'waybillsadd',
        },
        apiPath : {
            type : String,
            default : 'waybills/add',
        },
        submitButtonLabel: {
            type: String,
            default: "Submit",
        },
        msgAfterSave: {
            type: String,
            default: "Record added successfully",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type : Boolean,
            default : true,
        },
        isSubPage: {
            type : Boolean,
            default : false,
        },
        pageData: { // use to set formData default values from another page
            type: Object,
            default: () => {} 
        },
    });
    const app = useApp();
        const auth = useAuth();

//custom code
const formDefaultValuesCounter = {
    user: auth.userName, 
        ref: "", 
        logdate: utils.dateTimeNow(), 

};
 const formData2 = reactive({ ...formDefaultValuesCounter });
    const formDefaultValues = {
        tracking: "", 
        date: utils.dateTimeNow(), 
        control_number: '', 

    }
    const formDefaultValues2 = {
        date: utils.dateNow(), 
        tracking: "", 
       
        control_number: "",
    }

//add counter
const res = ref("");
async function addCounter(ref,date) {
  try{
    //fetch data from the api
    let response = await ApiService.get(`counter/addcounter/${ref}/${date}`);
    
    
    if(response.data.key = 1){
        app.flashMsg('Sucess', response.data.Message, 'success');
       getData();
    }
    else{
        app.flashMsg('Failed', "Something Went Wrong", 'error');
    }
  }
  catch(e){
    console.error(e); 
  }
}


//end adding counter code

//get next id from counters
const finalCount = ref(0);
async function getData() {
  try{
    //fetch data from the api
    let response = await ApiService.get("counter/getcounterid");
    let idd = parseInt(response.data[0].recid);
    let one = parseInt(1);
    let total = idd+one;

    
   finalCount.value =utils.dateNow().slice(-5).substring(0,2) + utils.dateNow().substring(0,4).slice(-2) +"-"+ total;
    formData2.ref = finalCount;
    formData.control_number = utils.dateNow().slice(-5).substring(0,2) + utils.dateNow().substring(0,4).slice(-2) +"-"+idd;
    formDefaultValues2.control_number = formData.control_number;

  }
  catch(e){
    console.error(e); 
  }
}
onMounted(()=>{
        getData();
    });
//end getting ids from counter
// enables v-focus in templates
const vFocus = {
  mounted: (el) => el.focus()
};
const re = ref({});
 async function postData(track) {
  try{ //post data to api
   

    let response = await ApiService.get(`waybills/addwaybill/${formData.control_number}/${track}/${formData.date}`, arrFormData);
   re.value = response.data
    // if(response.data.Key = 1){
    //     app.flashMsg('Success', response.data.Message, 'success');
    // }
    // else{
        
    //     app.flashMsg('Failed', response.data.Message, 'warning');
    
    // }
  }
  catch(e){
    console.error(e); 
  }
}

function dBounce(event){
clearTimeout(formDefaultValues.tracking)
      formDefaultValues.tracking = setTimeout(() => {  
       //add to api
//get last data from the array and add to database
let le= arrFormData.length - 1;
//console.log(arrFormData[le]);

       postData(arrFormData[le].tracking);
      
        addFormRow();

      }, 600)
}



//display the waybills after a ticket is generated
    const showWayBill=ref(false);
    function openWayBill(){
        showWayBill.value=true;
           }
////
//////













//open print modal
    const visibleFull = ref(false);
function doPrint () {
     // 1. Set the className of the area div to print
      var newstr = document.getElementsByClassName('print')[0].innerHTML
      // 2. Copy to body and execute window. print printing
      document.body.innerHTML = newstr
      // 3. Restore: Store the old page and return it to the page after printing.
      var oldstr = document.body.innerHTML
      window.print()
      document.body.innerHTML = oldstr
      return false
    }
//check waybill count
const total = ref(" ");
//call the api
async function getCn(steve) {
  try{
    //fetch data from the api
    let response = await ApiService.get(`waybills/checkcn/${steve}`);
    total.value = response.data;
  }
  catch(e){
    console.error(e); 
  }
}
//end waybill count
    const formData = reactive({ ...formDefaultValues });
    // perform custom validation before submit form
    // set custom form fields
    const arrFormData = reactive([formData]);
 function addFormRow() {
        
         
         //alert(arrFormData[index].tracking)
         
         getCn(arrFormData[0].control_number);
         //alert(arrFormData[0].tracking);

        //console.log(arrFormData[i].tracking);
 

         //console.log(arrFormData)
        arrFormData.push({ ...formDefaultValues2 });
    }
    function removeFormRow (index){
        arrFormData.splice(index, 1);
    }
    // return false if validation fails
    function beforeSubmit(){
        return true;
    }
    // after form submited to api
    // reset form data.
    // redirect to another page
    function onFormSubmited(response) {
        app.flashMsg(props.msgAfterSave);
        Object.assign(formData, formDefaultValues); //reset form data
        if(props.redirect) app.navigateTo(`/waybills`);
    }
    //form validation rules
    const rules = computed(() => {
        return {
            tracking: {  },
            date: {  },
            location: {  },
            city: {  },
            control_number: {  }
        }
    });
    const v$ = useVuelidate(rules, formData); // form validation
    const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
    //page state
    const {
        submitted, // form submitted state - Boolean
        saving, // form api submiting state - Boolean
        pageReady, // if api data loaded successfully
    } = toRefs(page.state);
    //page methods
    const {
        submitForm, //submit form data to api
        getErrorClass, // return error class if field is invalid- args(fieldname)
        getFieldError, //  get validation error message - args(fieldname)
        isFieldValid, // check if field is validated - args(fieldname)
         // map api datasource  to Select options label-value
    } = page.methods;
    onMounted(()=>{
        const pageTitle = "Add New Waybills";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
    });
    // expose page object for other components access
    defineExpose({
        page
    });




    
</script>
<style scoped>
</style>
<style scoped>

    @media print
    {
         
        span {
        font-size: 120pt;
    }
        
    }
</style>
